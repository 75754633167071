import SearchLocation from './Components/SearchLocation';

function App() {
  return (
    <div className="App">
      <div style={{
        padding: '20px'
      }}>
        <SearchLocation />

      </div>
    </div>
  );
}

export default App;
